<template>
  <div>
    <Report ref="report" filterKey="Date"
      :page-title="pageTitle"
      :fields="fields"
      :search-text="searchText"
      :fetch-function="fetchData"
      :include-retired="includeRetired">
      <template v-slot:details>
        <b-col lg="4">
          <b-table-simple small borderless responsive>
            <b-tbody>
              <b-tr>
                <b-th>LOTS:</b-th><b-td>{{ numberOfLots }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>LOCATIONS:</b-th><b-td>{{ numberOfLocations }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>ANIMALS:</b-th><b-td>{{ totalAnimals }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  INCLUDE RETIRED:
                </b-th>
                <b-td>
                  <b-form-group>
                    <b-form-radio-group v-model="includeRetired">
                      <b-form-radio :value="true">Yes</b-form-radio>
                      <b-form-radio :value="false">No</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </b-td>
              </b-tr>
              <b-tr v-if="$route.meta.eventType == 'LOA'">
                <b-button @click="downloadReports()" :disabled="selectedEvents.length <= 0">Download Combined Reports</b-button>
              </b-tr>
              <b-tr v-if="$route.meta.eventType == 'LOA' && selectedEvents.length > 0">
                <b-td>Animal Total: {{total}}</b-td>
              </b-tr>
              <b-tr v-if="$route.meta.eventType == 'LOA' && selectedEvents.length > 0">
                <b-table :items="selectedEvents" sticky-header="200px" bordered striped small responsive ref="table" :fields="fields"
                         v-bind="$attrs" sort-direction="desc"></b-table>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </template>
      <template v-slot:cell(lots)="data">
      <span v-for="(v, i) in data.value" :key="i">
        <span v-if="i != 0">, </span><b-link :to="{ name: 'LotReport', params: { locationId: data.item.location.id, lotId: v.id } }">{{ v.identifier }}</b-link>
      </span>
      </template>
      <template v-slot:cell(id)="{ value, item }" >
        <b-link :to="{ name: 'TagEventReport', params: { locationId: item.location.id, eventId: item.id } }"> {{ value }}</b-link>
      </template>
      <template v-slot:cell(lot.identifier)="data">
        <b-link :to="{ name: 'LotReport', params: { locationId: data.item.location.id, lotId: data.item.lot.id } }">{{ data.value }}</b-link>
      </template>
      <template v-slot:cell(event_end)="{ value, item }">
        <b-link :to="{ name: 'TagEventReport', params: { locationId: item.location.id, eventId: item.id } }">{{ value }}</b-link>
      </template>
      <template v-slot:cell(location.name)="data">
        <b-link :to="{ name: 'LocationReport', params: { locationId: data.item.location.id } }">{{ data.value }}</b-link>
      </template>
      <template v-slot:cell(location.memberorg.business_name)="data">
        <b-link :to="{ name: 'MemberLocations', params: { memberId: data.item.location.memberorg.id } }" v-if="data.value">{{ data.value }}</b-link>
      </template>
      <template v-slot:cell(process_button)="data">
        <b-button :to="{ name: 'LoadedProcessing', params: { eventId: data.item.id } }" size="sm">PROCESS</b-button>
      </template>
      <template v-slot:cell(select_button)="data">
        <b-button v-if="selectedEvents.indexOf(data.item) > -1" @click="selectEvent(data)" size="sm">REMOVE</b-button>
        <b-button v-else @click="selectEvent(data)" size="sm">ADD</b-button>
      </template>
    </Report>
  </div>
</template>

<script>
  import Report from '@/components/Report'
  import { tagEventTypes } from '@/variables'

  export default {
    name: 'Shipped',
    props: {
      searchText: String
    },
    components: {
      Report
    },
    data () {
      return {
        pageTitle: 'Shipping Events',
        total: null,
        numberOfLots: null,
        numberOfLocations: null,
        totalAnimals: null,
        includeRetired: false,
        selectedEvents: [],
        fieldsData: [
          {
            key: 'id',
            label: 'Event Number',
            sortable: true,
            formatter: value => this.decIndex(value)
          },
          {
            key: 'lot.identifier',
            label: 'Lot',
            // formatter: (value, key, item) => {
            //   let result = []
            //   value.forEach((i, index) => {
            //     result.push({ id: i, identifier: item.lot_identifiers[index] })
            //   })
            //   return result
            // },
            sortByFormatted: true,
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'animal_count',
            label: 'Animals',
            sortable: true
          },
          {
            key: 'event_end',
            label: 'Shipped',
            formatter: value => this.dateformat(value),
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'location.memberorg.business_name',
            label: 'Member',
            sortable: true
          },
          {
            key: 'location.name',
            label: 'Location',
            sortable: true
          },
          {
            key: 'gate_name',
            label: 'Gate',
            sortByFormatted: true,
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'qty',
            label: 'Notes',
            sortable: true
          },
        ]
      }
    },
    watch: {
      selectedEvents () {
        this.total = this.selectedEvents.reduce((accumulator, object) => {
          return accumulator + object.animal_count;
        }, 0);
      }
    },
    computed: {
      fields () {
        let fieldsCopy = [...this.fieldsData]
        if (!this.$store.getters.isMember) {
          fieldsCopy.push(
              {key: 'process_button', label: '', thStyle: 'width: 10px;', csvExcluded: true},
          )
        }
        fieldsCopy.push(
            { key: 'select_button', label: '', thStyle: 'width: 10px;', csvExcluded: true },
        )
        return fieldsCopy
      },
      filterDateRangeStart: {
        get () {
          if(this.$store.state.filterDateRangeStart === '' || this.$store.state.filterDateRangeStart === null){
            // get 5 days before
            // TODO make this a setting somewhere in account
            var tempDate = new Date();
            tempDate.setDate(tempDate.getDate() - 5);
            var tempDateStr = tempDate.toISOString().slice(0, 10);

            this.$store.commit('setFilterDateRangeStart', tempDateStr)
          }
          return this.$store.state.filterDateRangeStart
        },
        set (val) {
          this.$store.commit('setFilterDateRangeStart', val)
        }
      },
      filterDateRangeEnd: {
        get () {
          if(this.$store.state.filterDateRangeEnd === '' || this.$store.state.filterDateRangeEnd === null){
            // today
            // TODO make this a setting somewhere in account
            var todayDate = new Date().toISOString().slice(0, 10);
            this.$store.commit('setFilterDateRangeEnd', todayDate);
          }
          return this.$store.state.filterDateRangeEnd
        },
        set (val) {
          this.$store.commit('setFilterDateRangeEnd', val)
        }
      },
      filterDate: {
        get () {
          return this.$store.state.filterDate
        },
        set (val) {
          this.$store.commit('setFilterDate', val)
        }
      }
    },
    methods: {
      /**
       * This fetches all loading events
       * @param requestParams
       * @returns {*}
       */
      fetchData (requestParams) {
        let url = '/loaded/'
        url += '?' + requestParams.join('&') + '&get_all=true'
        if (this.includeRetired)
          url += '&include_retired=True'
        return this.$http.get(url)
          .then(response => {
            console.log('Shipped fetchData', response)
            this.numberOfLots = new Set(response.data.map(e => e.lot ? e.lot.id : null)).size
            this.numberOfLocations = new Set(response.data.map(e => e.location ? e.location.id : null)).size
            this.totalAnimals = response.data.reduce((accumulator, object) => {
              return accumulator + object.animal_count;
            }, 0);
            this.arrayCopy = response.data.sort((a, b) => (b.timestamp > a.timestamp) ? 1 : ((a.timestamp > b.timestamp) ? -1 : 0))
            return response.data
          })
      },
      decIndex(item) {
        const index = this.arrayCopy.slice(0).reverse().map(e => e.id).indexOf(item);
        return index+1
      },
      selectEvent(data) {
        const index = this.selectedEvents.indexOf(data.item)
        if (index > -1)
        {
          this.selectedEvents.splice(index, 1)
        } else {
          this.selectedEvents.push(data.item)
        }
      },
      async downloadReports() {
        let arr = []
        let csv = ''
        let report_fields = [
          {
            key: 'uhftag_tag_id',
            label: 'UHF Tag',
            sortable: true
          },
          {
            key: 'animal_lftag_tag_id',
            label: 'LF Tag',
            sortable: true
          },
          {
            key: 'timestamp',
            label: 'Scanned',
            formatter: value => this.dateformat(value),
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'female',
            label: 'Gender',
            formatter: value => {
              switch (value) {
                case true:
                  return 'Heifer'
                case false:
                  return 'Steer'
                default:
                  return 'N/A'
              }
            }
          },
          {
            key: 'event_type',
            label: 'Scan Type',
            formatter: value => tagEventTypes[value],
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'location_name',
            label: 'Location',
            sortByFormatted: true,
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'gate_name',
            label: 'Gate',
            sortByFormatted: true,
            filterByFormatted: true,
            sortable: true
          },
        ]
        let prependFields = []
        let prependTable = ''
        prependFields.push('Event Type')
        prependFields.push('Created')
        let event_type = 'Shipping'
        let today = new Date()
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
        prependTable += event_type + ',' + date+' '+time
        csv += prependFields.join(',') + '\n'
        csv += prependTable + '\n\n'
        let filtered = report_fields.filter(e => !e.csvExcluded)
        let fieldNames = filtered.map(e => e.label ? e.label : e.key)
        csv += fieldNames.join(',') + '\n'
        let end = false
        let sleep = ms => new Promise(r => setTimeout(r, ms))
        let waitFor = async function waitFor(f) {
          while (!f()) await sleep(1000)
          return f()
        }
        for (let event of this.selectedEvents) {
          this.$http.get(`tagscans/?event=${event.id}`)
              .then(response => {
                if (event == this.selectedEvents[this.selectedEvents.length - 1]) {
                  end = true
                }
                arr.push(response.data.results.map(item => filtered.map(field => this.byString(item, field)).join(',')).join('\n'))
                return null
              })
              .catch(e => {
                console.log(e, e.response)
              })
        }
        await waitFor(() => end)
        csv += arr.join('\n')
        let link = document.createElement('a')
        link.download = `${this.pageTitle.replace(/ /g, '_')}-export-${new Date().toISOString()}.csv`
        link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
        link.click()
        link.remove()
      },
      byString (item, field, separator='.') {
        let properties = field.key.includes(separator) ? field.key.split(separator) : [field.key]
        //let value = properties.reduce((prev, curr) => prev && prev[curr], item)
        let value = null
        for (let prop of properties)
        {
          if (value == null) // TODO: Make this work for all array indexes and all depths of keys
          {
            value = prop.includes('[0]') ? item[prop.substring(0, prop.indexOf('['))][0] : item[prop]
          } else
          {
            value = value[prop]
          }
        }
        // console.log(item, field, value)

        if (typeof field.formatter === 'function') {
          let formatted = field.formatter(value, field.key, item)
          // console.log(formatted)
          if (formatted != null) {
            let formatted_str = formatted.toString() // Force field to be String because only strings have .replace()
            if(typeof formatted === 'object') {// if field is an object separate with business name
              formatted_str = '' //TODO will not work in future if we use objects for other items
              for(let ele of formatted){
                formatted_str += ele.business_name + ' '
              }
            }
            return formatted_str ? formatted_str.replace(/,/g, '') : null // call formatter and remove commas
          }
          return ''
        } else {
          return value
        }
      }
    },
    beforeRouteLeave (to, from, next) {
      this.$store.commit('setFilterDateRangeStart', null)
      this.$store.commit('setFilterDateRangeEnd', null);
      next()
    },
  }
</script>

<style lang="scss" scoped>
</style>
