import { render, staticRenderFns } from "./EventsListReport.vue?vue&type=template&id=a889a1c6&scoped=true&"
import script from "./EventsListReport.vue?vue&type=script&lang=js&"
export * from "./EventsListReport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a889a1c6",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BTh, BTd, BTr, BFormRadio, BFormRadioGroup, BFormGroup, BButton, BTable, BTbody, BTableSimple, BCol, BLink, BModal} from 'bootstrap-vue'
    installComponents(component, {BTh, BTd, BTr, BFormRadio, BFormRadioGroup, BFormGroup, BButton, BTable, BTbody, BTableSimple, BCol, BLink, BModal})
    

export default component.exports