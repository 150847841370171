<template>
  <div>
    <Report ref="report" filterKey="Inducted"
      :page-title="pageTitle"
      :fields="fields"
      :search-text="searchText"
      sort-by="inducted"
      :fetch-function="fetchData">
      <template v-slot:details>
        <!-- number of lots, number of locations -->
        <b-col lg="4">
          <b-table-simple small borderless responsive>
            <b-tbody>
              <b-tr>
                <b-th>LOTS:</b-th><b-td>{{ numberOfLots }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>LOCATIONS:</b-th><b-td>{{ numberOfLocations }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>ANIMALS:</b-th><b-td>{{ totalAnimals }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </template>
        <template v-slot:cell(lot.location)="{ value }">
          <b-link :to="{ name: 'LocationReport', params: { locationId: value.id } }">{{ value.name }}</b-link>
        </template>
        <template v-slot:cell(lot.identifier)="{ item, value}">
          <b-link :to="{ name: 'LotReport', params: { locationId: item.lot.location.id, lotId: item.lot.id } }">{{ value }}</b-link>
        </template>
        <template v-slot:cell(pen)="{ item, value }">
          <b-link :to="{ name: 'TagEventReport', params: { locationId: item.lot.location.id, eventId: item.id } }">{{ value }}</b-link>
        </template>
        <template v-slot:cell(inducted)="data">
          <span class="text-split">
            <b-link :to="{ name: 'TagEventReport', params: { locationId: data.item.lot.location.id, eventId: data.item.id } }">{{ data.value }}</b-link>
            <span class="ml-2" v-if="$store.getters.isSupervisor"><b-button variant="info" size="sm" @click="confirmValidate(data)">Manual Validation</b-button></span>
            <span v-if="data.item.lot.memberorg.length == 0"><b-button variant="info" size="sm" @click="editLot(data)">Set Member</b-button></span>
          </span>
        </template>
    </Report>

    <b-modal :title="`Lot ${editItem.lot.identifier} - Set Member`" v-if="editItem" v-model="editShow">
      <b-form @submit.stop.prevent="saveLotMember">
        <b-row>
          <b-col>
            <table>
              <tr>
                <td><label for="member" class="required">MEMBER:</label></td>
                <td><b-form-select id="member" name="memberorg" v-model="form.memberorg" :options="memberOptions" /></td>
              </tr>
            </table>
          </b-col>
        </b-row>
        <input type="submit" class="d-none" />
      </b-form>
      <template v-slot:modal-footer>
        <div>
          <b-button class="mr-1" variant="secondary" @click="editShow = null">CANCEL</b-button>
          <b-button variant="primary" @click="saveLotMember" :disabled="form.memberorg == null">SAVE</b-button>
        </div>
      </template>
    </b-modal>

    <b-modal ref="confirmModal" title="Confirmation" v-if="$store.getters.isSupervisor"
      @ok="manualValidate" ok-title="Validate" ok-variant="secondary" cancel-variant="primary">
      Are you sure you want to mark animals in this induction as validated?
    </b-modal>
  </div>
</template>

<script>
  import Report from '@/components/Report'

  export default {
    name: 'ReadyForValidation',
    props: {
      searchText: String
    },
    components: { Report },
    data () {
      return {
        pageTitle: 'Ready for Validation',
        fields: [
          {
            key: 'lot.location',
            label: 'Location',
            sortable: true
          },
          {
            key: 'lot.identifier',
            label: 'Lot',
            sortable: true
          },
          {
            key: 'pen',
            label: 'Pen',
            // formatter: value => value.join(', '),
            sortByFormatted: true,
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'animal_count',
            // label: 'Animals',
            sortable: true
          },
          {
            key: 'inducted',
            formatter: value => this.dateformat(value),
            filterByFormatted: true,
            sortable: true
          },
        ],
        editItem: null,
        editIndex: null,
        editShow: false,
        numberOfLots: null,
        numberOfLocations: null,
        totalAnimals: null,
        memberOptions: [],
        form: {
          memberorg: null
        }
      }
    },
    watch: {
      editShow: function (newVal) {
        if (newVal && this.memberOptions.length == 0) {
          this.fetchMembers()
        }
      }
    },
    methods: {
      /**
       * This will fetch all lots that are ready for validation.
       * @param requestParams
       * @returns {*}
       */
      fetchData (requestParams) {
        let url = '/for-validation/?' + requestParams.join('&')
        if (!this.$store.getters.isPBFCStaff)
          url += `&memberorg=${this.$store.getters.memberorg}`
        return this.$http.get(url)
          .then(response => {
            console.log('ReadyForValidation fetchData', response)
            this.numberOfLots = new Set(response.data.map(e => e.lot ? e.lot.id : null)).size
            this.numberOfLocations = new Set(response.data.map(e => e.lot.location ? e.lot.location.id : null)).size
            this.totalAnimals = response.data.reduce((accumulator, object) => {
              return accumulator + object.animal_count;
            }, 0);
            return response.data
          })
      },
      /**
       * This fetches all members that can be assigned to a lot.
       */
      fetchMembers () {
        this.$http.get(`/memberorgs/?get_all=true`)
          .then(response => {
            console.log('ReadyForValidation fetchMembers', response)
            this.memberOptions = response.data.map(e => ({ value: e.id, text: `${e.business_name} (${e.member_id})` }))
          })
          .catch(e => {
            console.log('ReadyForValidation fetchMembers', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      editLot (data) {
        this.editItem = data.item
        this.editIndex = data.index
        this.form.memberorg = null
        this.editShow = true
      },
      saveLotMember () {
        this.$http.patch(`/lots/${this.editItem.lot.id}/`, this.form)
          .then(response => {
            console.log('ReadyForValidation saveLotMember', response)
            // update object in table
            this.editItem.lot.memberorg = response.data.memberorg
            this.$refs.report.onSave({ item: this.editItem, index: this.editIndex }, 'Event member')
            this.editShow = false
          })
          .catch(e => {
            console.log('ReadyForValidation saveLotMember', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      confirmValidate (data) {
        this.editItem = data.item
        this.editIndex = data.index
        this.$refs.confirmModal.show()
      },
      manualValidate () {
        this.$http.post(`/tagevents/validate/?induction=${this.editItem.id}`)
          .then(response => {
            console.log('ReadyForValidation manualValidate', response)
            this.$refs.report.items.splice(this.editIndex, 1)
            this.setAlert({ variant: 'success', message: 'Induction successfully validated.'})
          })
          .catch(e => {
            console.log('ReadyForValidation manualValidate', e, e.response)
            this.setAlert({ variant: 'danger', message: 'Error validating animals. ' + e })
          })
      }
    },
    beforeRouteLeave (to, from, next) {
      this.$store.commit('setFilterDateRangeStart', null)
      this.$store.commit('setFilterDateRangeEnd', null);
      next()
    },
  }
</script>
