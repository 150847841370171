<template>
  <div id="loaded-processing">
    <Report ref="report"
      :page-title="pageTitle"
      :crumbs="crumbsAppend"
      :fields="fields"
      :search-text="searchText"
      :fetch-function="fetchData"
      selectable select-mode="multi" @row-selected="onRowSelected">
        <template v-slot:details>
          <b-row>
            <b-col lg="8">
              <b-table-simple small borderless responsive>
                <b-tbody>
                  <b-tr>
                    <b-th>LOCATION:</b-th><b-td>{{ event.location != null ? event.location.name : null }}</b-td>
                    <!-- <b-th>LOT:</b-th>
                    <b-td v-if="lot != null">
                      {{ lot.identifier + (lot.retired ? ' (Retired)' : '') }} <b-button size="sm" @click="retireLot" v-if="!lot.retired">Retire Lot</b-button>
                    </b-td> -->
                    <b-td v-if="event.lot != null">
                      <b-button size="sm" v-b-modal.retire-confirmation :disabled="event.lot.retired">
                        <span v-if="!event.lot.retired">Retire Lot</span>
                        <span v-else>Retired</span>
                      </b-button>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>STARTED:</b-th><b-td>{{ dateformat(event.event_start) }}</b-td>
                    <b-th>ANIMALS SCANNED:</b-th><b-td>{{ event.count }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>ENDED:</b-th><b-td>{{ dateformat(event.event_end) }}</b-td>
                    <b-th>NOT PROCESSED:</b-th><b-td>{{ event.not_processed }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th></b-th><b-td></b-td>
                    <b-th></b-th><b-td></b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
          <b-row class="d-flex mb-2">
            <b-col class="d-flex justify-content-end">
              <b-button-group class="mr-2">
                <b-button @click="$refs.report.$refs.table.selectAllRows()">Select All</b-button>
                <b-button @click="$refs.report.$refs.table.clearSelected()">Clear Selected</b-button>
              </b-button-group>
              <b-button variant="info" @click="processItems" :disabled="selectedRows.length == 0">Mark Processed</b-button>
            </b-col>
          </b-row>
        </template>
        <template v-slot:cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </template>
    </Report>
    <b-modal id="retire-confirmation" title="Confirmation"
      @ok="retireLot" ok-title="Retire Lot" ok-variant="secondary" cancel-variant="primary">
      Are you sure you want to retire this lot?
    </b-modal>
  </div>
</template>

<script>
  import Report from '@/components/Report'
  import { tagEventTypes } from '@/variables'

  export default {
    name: 'LoadedProcessing',
    props: {
      searchText: String,
      crumbs: { type: Array, default: () => [] }
    },
    components: { Report },
    data () {
      return {
        pageTitle: 'Tag Event',
        crumbsAppend: [],
        fields: [
          {
            key: 'selected',
            label: '',
            class: 'text-center',
            thStyle: 'width: 2rem;',
            csvExcluded: true
          },
          {
            key: 'uhftag_tag_id',
            label: 'UHFTag ID',
            sortable: true
          },
          {
            key: 'lftag_tag_id',
            label: 'LFTag ID',
            sortable: true
          },
          {
            key: 'timestamp',
            label: 'Scanned',
            formatter: value => this.dateformat(value),
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'position',
            formatter: (value, key, item) => `${item.lat}, ${item.lon}`,
            sortByFormatted: true,
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'processed_ship',
            label: 'Processed',
            formatter: value => value == null ? 'No' : 'Yes',
            sortByFormatted: true,
            filterByFormatted: true,
            sortable: true
          }
        ],
        event: {},
        // notProcessedCount: '--',
        selectedRows: [],
      }
    },
    methods: {
      /**
       * Fetches data for a given event
       * @param requestParams
       * @returns {*}
       */
      fetchData (requestParams) {
        // Get event details
        return this.$http.get(`/tagevents/${this.$route.params.eventId}/`)
          .then(response => {
            console.log('LoadedProcessing fetchData', response)
            this.event = response.data
            this.pageTitle = `${tagEventTypes[this.event.event_type]} Event - Lot ${this.event.lot.identifier}`
            this.crumbs.concat([
              { text: `${tagEventTypes[this.event.event_type]} Event`, to: { name: this.$route.name, params: this.$route.params }  }
            ])
            return this.$http.get(`/process-loaded/${this.event.id}/?${requestParams.join('&')}`)
              .then(response => {
                console.log('LoadedProcessing fetchData tagscans', response)
                // this.notProcessedCount = response.data.results.filter(scan => scan.processed_ship == null).length
                return response.data
              })
          })
      },
      updateProcessed (numProcessed) {
        if (this.event.not_processed < numProcessed) {
          console.log('LoadedProcessing updateProcessed error. Unprocessed count:', this.event.not_processed, 'updating processed count:', numProcessed)
        }
        this.event.not_processed -= numProcessed
      },
      onRowSelected (items) {
        // console.log('onRowSelected', items)
        this.selectedRows = items
      },
      processItems () {
        if (this.selectedRows.length > 0) {
          let ids = this.selectedRows.map(e => e.animal)
          this.$http.post('/process-loaded/', ids)
            .then(response => {
              console.log('LoadedProcessing processItems', response)
              this.updateProcessed(response.data.length)
              this.$refs.report.populateTable()
            })
            .catch(e => {
              console.log('LoadedProcessing processItems', e, e.response)
            })
        }
      },
      retireLot () {
        let temp = this.event.lot.id
        temp.retired = new Date().toISOString()
        this.$http.patch(`/lots/${this.event.lot.id}/`, temp)
          .then(response => {
            console.log('LoadedProcessing retireLot', response)
            this.event.lot = response.data
          }).catch(e => {
            console.log('LoadedProcessing retireLot', e, e.response)
          });
      }
    }
  }
</script>
