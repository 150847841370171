<template>
  <div>
    <Report ref="report" filterKey="Date"
      :page-title="pageTitle"
      :fields="fields"
      :search-text="searchText"
      :fetch-function="fetchData"
      :include-retired="includeRetired">
      <template v-slot:details>
        <!-- number of lots, number of locations -->
        <b-col lg="4">
          <b-table-simple small borderless responsive>
            <b-tbody>
              <b-tr>
                <b-th>LOTS:</b-th><b-td>{{ numberOfLots }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>LOCATIONS:</b-th><b-td>{{ numberOfLocations }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>ANIMALS:</b-th><b-td>{{ totalAnimals }}</b-td>
              </b-tr>
              <b-tr v-if="$route.meta.eventType == 'IND'">
                <b-th>MANUAL COUNT:</b-th><b-td>{{ totalManualCount }}</b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  INCLUDE RETIRED:
                </b-th>
                <b-td>
                  <b-form-group>
                    <b-form-radio-group v-model="includeRetired">
                      <b-form-radio :value="true">Yes</b-form-radio>
                      <b-form-radio :value="false">No</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </b-td>
              </b-tr>
              <b-tr v-if="$route.meta.eventType == 'PRE'">
                <b-button @click="downloadManifests()" :disabled="selectedEvents.length <= 0">Download Combined Manifests</b-button>
              </b-tr>
              <b-tr v-if="$route.meta.eventType == 'EXC'">
                <b-button @click="downloadExceptionManifests()" :disabled="selectedEvents.length <= 0">Download Combined Reports</b-button>
              </b-tr>
              <b-tr v-if="($route.meta.eventType == 'PRE' || $route.meta.eventType == 'EXC') && selectedEvents.length > 0">
                <b-td>Animal Total: {{total}}</b-td>
              </b-tr>
              <b-tr v-if="($route.meta.eventType == 'PRE' || $route.meta.eventType == 'EXC') && selectedEvents.length > 0">
                <b-table :items="selectedEvents" sticky-header="200px" bordered striped small responsive ref="table" :fields="preLoadingFields"
                         v-bind="$attrs" sort-direction="desc"></b-table>
              </b-tr>
              <b-tr v-if="$route.meta.eventType == 'TRA'">
                <b-button @click="downloadReports()" :disabled="selectedEvents.length <= 0">Download Combined Reports</b-button>
              </b-tr>
              <b-tr v-if="$route.meta.eventType == 'TRA' && selectedEvents.length > 0">
                <b-td>Animal Total: {{total}}</b-td>
              </b-tr>
              <b-tr v-if="$route.meta.eventType == 'TRA' && selectedEvents.length > 0">
                <b-table :items="selectedEvents" sticky-header="200px" bordered striped small responsive ref="table" :fields="fields"
                         v-bind="$attrs" sort-direction="desc"></b-table>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </template>
      <template v-slot:cell(id)="{ value, item }" >
        <b-link :to="{ name: 'TagEventReport', params: { locationId: item.location.id, eventId: item.id } }"> {{ value }}</b-link>
      </template>
      <template v-slot:cell(lot.identifier)="{ value, item }">
        <b-link :to="{ name: 'LotReport', params: { locationId: item.location.id, lotId: item.lot.id } }" v-if="value">{{ value }}</b-link>
      </template>
      <template v-slot:cell(location.name)="{ value, item }">
        <b-link :to="{ name: 'LocationReport', params: { locationId: item.location.id } }" v-if="value">{{ value }}</b-link>
      </template>
      <template v-slot:cell(event_end)="{ value, item }">
        <b-link :to="{ name: 'TagEventReport', params: { locationId: item.location.id, eventId: item.id } }">{{ value }}</b-link>
      </template>
      <template v-slot:cell(qty)="{ value, item }">
        <b-link :to="{ name: 'TagEventReport', params: { locationId: item.location.id, eventId: item.id } }">{{ value }}</b-link>
      </template>
      <template #cell(event_type)="data">
        <span class="text-split">
          {{ data.value }}
          <span v-if="$store.getters.isSupervisor"><b-button v-if="data.item.event_type == 'MAN'" size="sm" @click="confirmUndo(data)">Undo Validation</b-button></span>
        </span>
      </template>
      <template v-slot:cell(select_button)="data">
        <div v-if="$route.meta.eventType == 'TRA' || $route.meta.eventType == 'PRE' || $route.meta.eventType == 'EXC'">
          <b-button v-if="selectedEvents.indexOf(data.item) > -1" @click="selectEvent(data)" size="sm">REMOVE</b-button>
          <b-button v-else @click="selectEvent(data)" size="sm">ADD</b-button>
        </div>
      </template>
    </Report>

    <b-modal ref="confirmModal" title="Confirmation" v-if="$store.getters.isSupervisor"
      @ok="undoValidate" ok-title="Undo" ok-variant="secondary" cancel-variant="primary">
      Are you sure you want to undo this manual validation? The event will be deleted and animals marked as not validated.
    </b-modal>
  </div>
</template>

<script>
  import Report from '@/components/Report'
  import { tagEventTypes } from '@/variables'

  export default {
    name: 'EventsListReport',
    props: {
      searchText: String,
    },
    components: { Report },
    data () {
      return {

        fieldsData: [
          {
            key: 'id',
            label: 'Event Number',
            sortable: true,
            formatter: value => this.decIndex(value)
          },
          {
            key: 'lot.identifier',
            label: 'Lot',
            sortable: true
          },
          {
            key: 'location.name',
            label: 'Location',
            sortable: true
          },
          {
            key: 'gate_name',
            label: 'Gate',
            sortable: true
          },
          {
            key: 'event_end',
            label: 'Date',
            formatter: value => this.dateformat(value),
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'animal_count',
            label: 'Animals',
            sortable: true
          },
        ],
        preLoadingFields: [
          {
            key: 'id',
            label: 'Event Number',
            sortable: true,
            formatter: value => this.decIndex(value)
          },
          {
            key: 'lot.identifier',
            label: 'Lot',
            sortable: true
          },
          {
            key: 'location.name',
            label: 'Location',
            sortable: true
          },
          {
            key: 'gate_name',
            label: 'Gate',
            sortable: true
          },
          {
            key: 'event_end',
            label: 'Date',
            formatter: value => this.dateformat(value),
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'animal_count',
            label: 'Animals',
            sortable: true
          },
        ],
        initialized: false,
        numberOfLots: null,
        numberOfLocations: null,
        editItem: null,
        editIndex: null,
        itemIndex: null,
        total: null,
        totalAnimals: null,
        totalManualCount: null,
        includeRetired: false,
        selectedEvents: []
      }
    },
    watch: {
      selectedEvents () {
        this.total = this.selectedEvents.reduce((accumulator, object) => {
          return accumulator + object.animal_count;
        }, 0);
      }
    },
    computed: {
      pageTitle () {
        switch (this.$route.meta.eventType) {
          case 'IND':
            return 'Induction Events'
          case 'VAL':
            return 'Validation Events'
          case 'INV':
            return 'Inventory Events'
          case 'PRE':
            return 'Pre-Loading Events'
          case 'TRA':
            return 'Transfer Events'
          case 'EXC':
            return 'Exception Events'
          default:
            return 'Unknown Page'
        }
      },
      fields () {
        // only showing pen column on induction events page
        let fieldsCopy = [...this.fieldsData]
        switch(this.$route.meta.eventType) {
          case 'IND':
            fieldsCopy.splice(2, 0, {
              key: 'pen',
              sortable: true
            })
              fieldsCopy.push(
                  { key: 'manual_count', label: 'Manual Count', sortable: true, formatter: v => v != null ? v : 'N/A' },
                  { key: 'qty', label: 'Notes', sortable: true }
              )
            return fieldsCopy
          case 'VAL':
            fieldsCopy.push(
              { key: 'validation_percent', label: 'Validation %', sortable: true, formatter: v => v.toFixed(1) },
              { key: 'user_full_name', label: 'Validated By', sortable: true },
              { key: 'event_type', sortable: true, formatter: v => v == 'MAN' ? 'Manual' : 'Mobile' },
              { key: 'qty', label: 'Notes', sortable: true }
            )
            return fieldsCopy
          case 'INV':
            fieldsCopy.push(
                { key: 'user_full_name', label: 'Inventory By', sortable: true },
                { key: 'qty', label: 'Notes', sortable: true }
            )
            return fieldsCopy
          case 'PRE':
            fieldsCopy.push(
                { key: 'select_button', label: '', thStyle: 'width: 10px;', csvExcluded: true }
            )
            return fieldsCopy
          case 'EXC':
            fieldsCopy.push(
                { key: 'select_button', label: '', thStyle: 'width: 10px;', csvExcluded: true }
            )
            return fieldsCopy
          case "TRA":
            fieldsCopy.push(
                { key: 'event_type', label: 'Event Type', sortable: true, formatter: v => v == 'TRI' ? 'Transfer In' : 'Transfer Out' },
                { key: 'select_button', label: '', thStyle: 'width: 10px;', csvExcluded: true }
            )
            return fieldsCopy
          default:
            fieldsCopy.push(
                { key: 'qty', label: 'Notes', sortable: true }
            )
            return fieldsCopy
        }
      }
    },
    methods: {
      /**
       * This fetches all tagevents of the specified type
       * @param requestParams
       * @returns {*}
       */
      fetchData (requestParams) {
        let url = `/tagevents/?event_type=${this.$route.meta.eventType}`
        if (this.includeRetired)
          url += '&include_retired=True'
        if (this.$route.meta.eventType == 'VAL')
          url += '&event_type=MAN'
        url += '&' + requestParams.join('&')
        return this.$http.get(url)
          .then(response => {
            console.log('EventsListReport fetchData', response)
            this.numberOfLots = new Set(response.data.map(e => e.lot ? e.lot.id : null)).size
            this.numberOfLocations = new Set(response.data.map(e => e.location ? e.location.id : null)).size
            this.totalAnimals = response.data.reduce((accumulator, object) => {
              return accumulator + object.animal_count;
            }, 0);
            if (this.$route.meta.eventType == 'IND') {
              this.totalManualCount = response.data.reduce((accumulator, object) => {
                return accumulator + object.manual_count;
              }, 0);
            }
            this.arrayCopy = response.data
            return response.data
          })
      },
      confirmUndo (data) {
        this.editItem = data.item
        this.editIndex = data.index
        this.$refs.confirmModal.show()
      },
      undoValidate () {
        this.$http.delete(`/tagevents/validate/${this.editItem.id}/`)
          .then(response => {
            console.log('EventsListReport undoValidate', response)
            this.$refs.report.onDelete(this.editIndex, 'Manual validation')
          })
          .catch(e => {
            console.log('EventsListReport undoValidate', e, e.response)
            this.setAlert({ variant: 'danger', message: 'Error rolling back manual validation.' + e })
          })
      },
      decIndex(item) {
        const index = this.arrayCopy.slice(0).reverse().map(e => e.id).indexOf(item);
        return index+1

      },
      async downloadManifests() {
        let combinedManifest = ""
        let prependFields = []
        let prependTable = ''
        prependFields.push('Event Type')
        prependFields.push('Created')
        let event_type = ''
        switch (this.$route.meta.eventType) {
          case 'IND':
            event_type = 'Induction'
            break
          case 'VAL':
            event_type = 'Validation'
            break
          case 'INV':
            event_type = 'Inventory'
            break
          case 'PRE':
            event_type = 'Pre-Loading'
            break
          case 'TRA':
            event_type = 'Transfer'
            break
          case 'EXC':
            event_type = 'Exception'
            break
          default:
            event_type = 'Unknown'
            break
        }
        let today = new Date()
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
        prependTable += event_type + ',' + date+' '+time
        combinedManifest += prependFields.join(',') + '\n'
        combinedManifest += prependTable + '\n\n'
        let end = false
        let first = true
        let sleep = ms => new Promise(r => setTimeout(r, ms))
        let waitFor = async function waitFor(f) {
          while (!f()) await sleep(1000)
          return f()
        }
        for (let event of this.selectedEvents) {
          this.$http.get(`/preloading-manifest/${event.id}/`, {responseType: 'text'})
              .then(response => {
                console.log('PreloadingManifest fetchData', response)
                if (event == this.selectedEvents[this.selectedEvents.length - 1]) {
                  end = true
                }
                if (first) {
                  combinedManifest += response.data.slice(0, response.data.lastIndexOf("\n"))
                  first = false
                } else {
                  combinedManifest += response.data.slice(response.data.indexOf("\n"), response.data.length - 1)
                }
              })
              .catch(e => {
                console.log(e, e.response)
              })
        }
        await waitFor(() => end)
        let link = document.createElement('a');
        link.href = 'data:text/csv;charset=utf-8,' + encodeURI(combinedManifest)
        link.download = "CombinedPreloadingManifest.csv";
        link.click();
      },
      async downloadExceptionManifests() {
        let combinedManifest = ""
        let prependFields = []
        let prependTable = ''
        prependFields.push('Event Type')
        prependFields.push('Created')
        let event_type = ''
        switch (this.$route.meta.eventType) {
          case 'IND':
            event_type = 'Induction'
            break
          case 'VAL':
            event_type = 'Validation'
            break
          case 'INV':
            event_type = 'Inventory'
            break
          case 'PRE':
            event_type = 'Pre-Loading'
            break
          case 'TRA':
            event_type = 'Transfer'
            break
          case 'EXC':
            event_type = 'Exception'
            break
          default:
            event_type = 'Unknown'
            break
        }
        let today = new Date()
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
        prependTable += event_type + ',' + date+' '+time
        combinedManifest += prependFields.join(',') + '\n'
        combinedManifest += prependTable + '\n\n'
        let end = false
        let first = true
        let sleep = ms => new Promise(r => setTimeout(r, ms))
        let waitFor = async function waitFor(f) {
          while (!f()) await sleep(1000)
          return f()
        }
        for (let event of this.selectedEvents) {
          this.$http.get(`/exception-manifest/${event.id}/`, {responseType: 'text'})
              .then(response => {
                console.log('ExceptionManifest fetchData', response)
                if (event == this.selectedEvents[this.selectedEvents.length - 1]) {
                  end = true
                }
                if (first) {
                  combinedManifest += response.data.slice(0, response.data.lastIndexOf("\n"))
                  first = false
                } else {
                  combinedManifest += response.data.slice(response.data.indexOf("\n"), response.data.length - 1)
                }
              })
              .catch(e => {
                console.log(e, e.response)
              })
        }
        await waitFor(() => end)
        let link = document.createElement('a');
        link.href = 'data:text/csv;charset=utf-8,' + encodeURI(combinedManifest)
        link.download = "CombinedExceptionManifest.csv";
        link.click();
      },
      selectEvent(data) {
        const index = this.selectedEvents.indexOf(data.item)
        if (index > -1)
        {
          this.selectedEvents.splice(index, 1)
        } else {
          this.selectedEvents.push(data.item)
        }
      },
      async downloadReports() {
        let arr = []
        let csv = ''
        let report_fields = [
          {
            key: 'uhftag_tag_id',
            label: 'UHF Tag',
            sortable: true
          },
          {
            key: 'animal_lftag_tag_id',
            label: 'LF Tag',
            sortable: true
          },
          {
            key: 'timestamp',
            label: 'Scanned',
            formatter: value => this.dateformat(value),
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'female',
            label: 'Gender',
            formatter: value => {
              switch (value) {
                case true:
                  return 'Heifer'
                case false:
                  return 'Steer'
                default:
                  return 'N/A'
              }
            }
          },
          {
            key: 'event_type',
            label: 'Scan Type',
            formatter: value => tagEventTypes[value],
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'location_name',
            label: 'Location',
            sortByFormatted: true,
            filterByFormatted: true,
            sortable: true
          },
          {
            key: 'gate_name',
            label: 'Gate',
            sortByFormatted: true,
            filterByFormatted: true,
            sortable: true
          },
        ]
        let prependFields = []
        let prependTable = ''
        prependFields.push('Event Type')
        prependFields.push('Created')
        let event_type = ''
        switch (this.$route.meta.eventType) {
          case 'IND':
            event_type = 'Induction'
            break
          case 'VAL':
            event_type = 'Validation'
            break
          case 'INV':
            event_type = 'Inventory'
            break
          case 'PRE':
            event_type = 'Pre-Loading'
            break
          case 'TRA':
            event_type = 'Transfer'
            break
          default:
            event_type = 'Unknown'
            break
        }
        let today = new Date()
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
        prependTable += event_type + ',' + date+' '+time
        csv += prependFields.join(',') + '\n'
        csv += prependTable + '\n\n'
        let filtered = report_fields.filter(e => !e.csvExcluded)
        let fieldNames = filtered.map(e => e.label ? e.label : e.key)
        csv += fieldNames.join(',') + '\n'
        let end = false
        let sleep = ms => new Promise(r => setTimeout(r, ms))
        let waitFor = async function waitFor(f) {
          while (!f()) await sleep(1000)
          return f()
        }
        let args = ['get_all=true']
        for (let event of this.selectedEvents) {
          this.$http.get(`tagscans/?event=${event.id}&${args.join('&')}`)
              .then(response => {
                if (event == this.selectedEvents[this.selectedEvents.length - 1]) {
                    end = true
                }
                arr.push(response.data.map(item => filtered.map(field => this.byString(item, field)).join(',')).join('\n'))
                return null
              })
              .catch(e => {
                console.log(e, e.response)
              })
        }
        await waitFor(() => end)
        csv += arr.join('\n')
        let link = document.createElement('a')
        link.download = `${this.pageTitle.replace(/ /g, '_')}-export-${new Date().toISOString()}.csv`
        link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
        link.click()
        link.remove()
      },
      byString (item, field, separator='.') {
        let properties = field.key.includes(separator) ? field.key.split(separator) : [field.key]
        //let value = properties.reduce((prev, curr) => prev && prev[curr], item)
        let value = null
        for (let prop of properties)
        {
          if (value == null) // TODO: Make this work for all array indexes and all depths of keys
          {
            value = prop.includes('[0]') ? item[prop.substring(0, prop.indexOf('['))][0] : item[prop]
          } else
          {
            value = value[prop]
          }
        }
        // console.log(item, field, value)

        if (typeof field.formatter === 'function') {
          let formatted = field.formatter(value, field.key, item)
          // console.log(formatted)
          if (formatted != null) {
            let formatted_str = formatted.toString() // Force field to be String because only strings have .replace()
            if(typeof formatted === 'object') {// if field is an object separate with business name
              formatted_str = '' //TODO will not work in future if we use objects for other items
              for(let ele of formatted){
                formatted_str += ele.business_name + ' '
              }
            }
            return formatted_str ? formatted_str.replace(/,/g, '') : null // call formatter and remove commas
          }
          return ''
        } else {
          return value
        }
      }
    },
    beforeRouteLeave (to, from, next) {
      this.$store.commit('setFilterDateRangeStart', null)
      this.$store.commit('setFilterDateRangeEnd', null);
      next()
    },
  }
</script>

<style lang="css" scoped>
</style>
